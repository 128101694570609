import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";

import "./piecharts.css";

const COLORS_SINGLE = [
    '#004c6d',
    '#126788',
    '#2584a2',
    '#39a1bb',
    '#50c0d3',
    '#6adfea',
    '#87ffff',

    //Greens
    '#42F4E4',
    '#26D7C8',
    '#0ABCAC',
    '#009B8E',
    '#00756B',
    '#00534C',
    // '#D6D5FF'
]

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload
    // value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 10;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
        <text className="middleLabel" x={cx} y={cy} dy={8} textAnchor="middle" fill={'#FFFFFF'}>
          {payload.name}
        </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey - 3}
        textAnchor={textAnchor}
        fill="#CFFFFF"
        className="value"
      >
          {`${Math.round(payload.value)}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 13}
        y={ey + 9}
        textAnchor={textAnchor}
        fill="#CFFFFF"
        className="units"
        >
            {payload.units}
        </text>
    </g>
  );
};

export default function Pies(props: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <div style={{ width: 300, height: 260 }}>
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={props.data}
                    innerRadius={70}
                    outerRadius={90}
                    stroke="#8FD3D8"
                    fill="#8FD3D8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                    
                >
                    {props.data.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS_SINGLE[index % COLORS_SINGLE.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    </div>
  );
}
