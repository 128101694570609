export const AVG_TOTAL_VOLUME = 1685;
export const MAX_VOLUME = AVG_TOTAL_VOLUME;
export const GALLONS_TO_LITERS = 3.785411784;

export const AVG_VOUMES = {
  shower: 11,
  bath: 2,
  bathroomSink: 3,
  toilet: 14,
  kitchenSink: 7,
  dishWashing: 1,
  laundry: 10,
  greywater: -25,
  shopping: 583,
  recycling: -13,
  diet: 1036,
  caffeine: 30,
}

export const TIME_INTERVALS = [
  { text: "Day", value: 1 },
  { text: "Week", value: 7 },
  { text: "Month", value: 30 },
  { text: "Year", value: 365 },
];
export const SHOWER_PER_PERSON = {
  "<5": 20,
  "5-10": 40,
  "11-15": 60,
  ">15": 75,
};
export const SHOWER_EFFICIENCIES = {
  yes: 0.5,
  no: 1,
  some: 0.75,
};
export const BATH_PER_DAY = 35;
export const BATHROOM_SINK_PER_PERSON = {
  "<5": 20,
  "5-10": 40,
  "11-30": 100,
  ">30": 150,
};
export const BATHROOM_SINK_EFFICIENCIES = {
  yes: 0.3,
  no: 1,
  some: 2 / 3,
};
export const TOILET_PER_PERSON = {
  yes: 0,
  no: 25,
  sometimes: 17,
};
export const TOILET_EFFICIENCIES = {
  yes: 0.3,
  no: 1,
  some: 2 / 3,
};
export const KITCHEN_SINK_PER_PERSON = {
  "<5": 20,
  "5-20": 65,
  "21-45": 150,
  ">45": 250,
};
export const KITCHEN_SINK_EFFICIENCIES = {
  yes: 0.3,
  no: 1,
};
export const DISHWASHING_VOLUMES = [15, 4, 27];
export const DISPOSABLE_DISHES_PER_PERSON_VOLUME = 5;
export const LAUNDRY_VOLUMES = [41, 20, 25];
export const DRY_CLEANING_VOLUME = 38;
export const GREYWATER_VOLUME = -63;

export const VOLUME_PER_MILE = 3/4;
export const SHOPPING_VOLUMES = {
  low: 291,
  medium: 538,
  high: 1400
};
export const RECYCLE_PAPER_VOLUMES = {
  all: -4,
  some: -2,
  none: 0
};
export const RECYCLE_PLASTIC_VOLUMES = {
  all: -16,
  some: -8,
  none: 0
};
export const RECYCLE_BOTTLES_VOLUMES = {
  all: -30,
  some: -15,
  none: 0
};
export const RECYCLE_FABRICS_VOLUMES = {
  all: -6,
  some: -3,
  none: 0
};
export const DIET_VOLUMES = {
  vegan: 406,
  vegetarian: 563,
  meat: 1283
};
export const MEAT_CONSUMPTION_VOLUMES = {
  some: -687,
  once: -515,
  twice: -344,
  every: 0
};
export const PET_FOOD_VOLUME = 100/15;
export const CAFFEINE_VOLUMES = {
  tea: 8,
  coffee: 37
};
