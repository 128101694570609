// import { render } from '@testing-library/react';
import React from "react";
import ReactDOM from "react-dom";
import firebase from 'firebase/app';
import "firebase/analytics";
import "./index.css";
import "./wave.css";
import * as volumes from "./constants.js";
import Pies from "./piecharts.tsx";

let index = 0;
var resizeTimer;

const firebaseConfig = {
  apiKey: "AIzaSyAmgxwO2j8u6ioIYrs23BfYQ1elIwJsLcY",
  authDomain: "waterfootprint-dace5.firebaseapp.com",
  projectId: "waterfootprint-dace5",
  storageBucket: "waterfootprint-dace5.appspot.com",
  messagingSenderId: "171934296013",
  appId: "1:171934296013:web:6d96a3cc7a248f6e814684",
  measurementId: "G-VWFWMFZNM0"
};
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

window.addEventListener(
  "resize",
  () => {
    var quizContainer = document.getElementById("quizContainer");
    // quizContainer.style.overflow = "initial";
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      quizContainer.style.scrollBehavior = "initial";
      // quizContainer.style.overflow = "hidden";
      document.getElementsByClassName("questionContainer")[index].scrollIntoView();
      quizContainer.style.scrollBehavior = "smooth";
    }, 100);
  },
  true
);

function scrollBack() {
  let questions = document.getElementsByClassName("questionContainer");
  let numQuestions = questions.length - 1;
  let increment = 100 / numQuestions;
  let progress = document.getElementById("progress");
  if (index > 0) {
    index -= 1;
    progress.style.width = increment * index + "%";
    questions[index].scrollIntoView();
  }
  if (index !== questions.length - 1) {
    document.getElementsByClassName("progressBack")[0].style.opacity = "1";
  }
}

function scrollNext() {
  let questions = document.getElementsByClassName("questionContainer");
  let numQuestions = questions.length - 1;
  let increment = 100 / numQuestions;
  let progress = document.getElementById("progress");
  if (index < questions.length - 1) {
    index += 1;
    progress.style.width = increment * index + "%";
    questions[index].scrollIntoView();
  }
  if (index === questions.length - 1) {
    document.getElementsByClassName("progressBack")[0].style.opacity = "0";
  }
}

// function scrollLast() {
//   let questions = document.getElementsByClassName("questionContainer");
//   let numQuestions = questions.length - 1;
//   let increment = 100 / numQuestions;
//   let progress = document.getElementById("progress");
//   if (index < questions.length - 1) {
//     index += questions.length - 1;
//     progress.style.width = increment * index + "%";
//     questions[index].scrollIntoView();
//   }
// }

class NavBar extends React.Component {
  render() {
    return (
      <div className="nav">
        <button
          className="back icon"
          style={{ display: this.props.hide ? "none" : "initial" }}
          onClick={scrollBack}
        >
          {this.props.back ? this.props.back : "Back"}
        </button>
        <button
          className="next"
          style={{ marginLeft: this.props.hide ? "0" : "20px" }}
          onClick={scrollNext}
          disabled={this.props.disabled}
        >
          {this.props.next ? this.props.next : "Next"}
        </button>
      </div>
    );
  }
}

function TitlePage(props) {
  return (
    <div className="questionContainer">
      <div className="innerContainer">
        <h3 className="question">{props.text}</h3>
        <div
          className="description"
          style={{ display: props.description ? "initial" : "none" }}
        >
          {props.description}
        </div>
        <NavBar hide={props.hide} back={props.back} next={props.next} />
      </div>
    </div>
  );
}

function MultipleChoiceQuestion(props) {
  if (props.hide) {
    return null;
  }
  return (
    <div className="questionContainer" onChange={props.onChange}>
      <div className="innerContainer">
        <h3 className="question">{props.question}</h3>
        {props.description ? (
          <div className="description">{props.description}</div>
        ) : null}
        {props.choices.map((item, i) => {
          return (
            <label className="radioContainer" key={i}>
              <input type="radio" name={props.name} value={item.value} />
              <span className="radioButton">{item.text}</span>
            </label>
          );
        })}
        <NavBar
          disabled={!props.hasResponded}
          back={props.back}
          next={props.next}
        />
      </div>
    </div>
  );
}

class IntegerQuestion extends React.Component {
  renderInteger(props) {
    return (
      <NumberPicker
        value={props.value}
        min={props.min}
        max={props.max}
        minus={props.minus}
        plus={props.plus}
      />
    );
  }

  render() {
    return (
      <div className="questionContainer">
        <div className="innerContainer">
          <h3 className="question">{this.props.question}</h3>
          {this.props.description ? (
            <div className="description">{this.props.description}</div>
          ) : null}
          {this.renderInteger(this.props)}
          <NavBar back={this.props.back} next={this.props.next} />
        </div>
      </div>
    );
  }
}

function NumberPicker(props) {
  return (
    <div className={props.className ? props.className : "numberPicker"}>
      <button
        className="icon minus"
        onClick={props.value > props.min ? props.minus : null}
      >
        -
      </button>
      <span className="pickerValue">{props.value}</span>
      <button
        className="icon plus"
        onClick={
          props.value < (props.max != null ? props.max : Infinity)
            ? props.plus
            : null
        }
      >
        +
      </button>
    </div>
  );
}

class ComplexQuestion extends React.Component {
  renderInteger(props) {
    return (
      <NumberPicker
        value={props.value}
        min={props.min}
        max={props.max}
        minus={props.decreaseValue}
        plus={props.increaseValue}
      />
    );
  }

  renderTimeFrame(props) {
    return (
      <TimeFramePicker
        timeIndex={props.timeIndex}
        minus={props.decreaseTime}
        plus={props.increaseTime}
      />
    );
  }

  render() {
    return (
      <div className="questionContainer">
        <div className="innerContainer">
          <h3 className="question">{this.props.question}</h3>
          {this.props.description ? (
            <div className="description">{this.props.description}</div>
          ) : null}
          {this.renderInteger(this.props)}
          <div className="per">per</div>
          {this.renderTimeFrame(this.props)}
          <NavBar back={this.props.back} next={this.props.next} />
        </div>
      </div>
    );
  }
}

function TimeFramePicker(props) {
  return (
    <div className={props.className ? props.className : "timeFramePicker"}>
      <button
        className="icon minus"
        onClick={props.timeIndex > 0 ? props.minus : null}
      >
        -
      </button>
      <span className="pickerValue">
        {volumes.TIME_INTERVALS[props.timeIndex].text}
      </span>
      <button
        className="icon plus"
        onClick={
          props.timeIndex < volumes.TIME_INTERVALS.length - 1
            ? props.plus
            : null
        }
      >
        +
      </button>
    </div>
  );
}

function MultipleComplexQuestion(props) {
  return (
    <div className="multiComplexQuestion questionContainer">
      <div className="innerContainer">
        <h3 className="question">{props.question}</h3>
        {props.description ? (
          <div className="description">{props.description}</div>
        ) : null}
        {props.subquestions.map((subquestion, i) => {
          return (
            <div key={i}>
              <h4 className="subquestion">{subquestion.question}</h4>
              <div className="subquestionButton">
                <span onClick={props.onChange}>
                  <NumberPicker
                    className={"numberPickerMini"}
                    value={subquestion.value}
                    min={subquestion.min}
                    max={subquestion.max}
                    minus={subquestion.decreaseValue}
                    plus={subquestion.increaseValue}
                  />
                </span>
                <span className="perMini">times per</span>
                <TimeFramePicker
                  className={"timeFramePickerMini"}
                  timeIndex={subquestion.timeIndex}
                  minus={subquestion.decreaseTime}
                  plus={subquestion.increaseTime}
                />
              </div>
            </div>
          );
        })}
        <NavBar
          disabled={!props.hasResponded}
          back={props.back}
          next={props.next}
        />
      </div>
    </div>
  );
}

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testSubquestions: [
        {
          value: 0,
          timeIndex: 0,
        },
        {
          value: 0,
          timeIndex: 0,
        },
      ],
      testSubquestionsResponse: false,

      household: 1,
      showerVolume: 0,
      showerResponse: false,
      showerEfficiency: 1,
      showerEfficiencyResponse: false,
      bathValue: 0,
      bathIndex: 0,
      bathroomSinkVolume: 0,
      bathroomSinkResponse: false,
      bathroomSinkEfficiency: 1,
      bathroomSinkEfficiencyResponse: false,
      toiletVolume: 0,
      toiletResponse: false,
      toiletEfficiency: 1,
      toiletEfficiencyResponse: false,
      kitchenSinkVolume: 0,
      kitchenSinkResponse: false,
      kitchenEfficiency: 1,
      kitchenEfficiencyResponse: false,
      dishWashing: [
        { value: 0, timeIndex: 0 },
        { value: 0, timeIndex: 0 },
        { value: 0, timeIndex: 0 },
      ],
      dishWashingResponse: false,
      disposableDishesValue: 0,
      disposableDishesIndex: 0,
      laundry: [
        { value: 0, timeIndex: 0 },
        { value: 0, timeIndex: 0 },
        { value: 0, timeIndex: 0 },
      ],
      laundryResponse: false,
      dryCleaningValue: 0,
      dryCleaningIndex: 0,
      greywaterValue: 0,
      greywaterResponse: false,

      //VIRTUAL VOLUMES
      milesDriven: 0,
      shoppingVolume: 0,
      shoppingResponse: false,
      recyclePaperVolume: 0,
      recyclePaperResponse: false,
      recyclePlasicVolume: 0,
      recyclePlasicResponse: false,
      recycleBottlesVolume: 0,
      recycleBottlesResponse: false,
      recycleFabricVolume: 0,
      recycleFabricResponse: false,
      dietVolume: 0,
      dietResponse: false,
      hideMeatQuestion: true,
      meatConsumptionValue: 0,
      meatConsumptionResponse: 0,

      caffeine: [
        { value: 0, timeIndex: 0 },
        { value: 0, timeIndex: 0 },
      ],

      householdTotal: 0,
      personalTotal: 0,
      totalGallons: 0,

      showerTotal: 0,
      bathTotal: 0,
      bathroomSinkTotal: 0,
      toiletTotal: 0,
      kitchenSinkTotal: 0,
      dishWashingTotal: 0,
      laundryTotal: 0,
      greyWaterTotal: 0,

      milesDrivenTotal: 0,
      shoppingTotal: 0,
      recycleTotal: 0,
      dietTotal: 0,
      caffeineTotal: 0,
      

      unitsMultiplier: 1,
      unitsText: 'gal/day',
      unitsResponse: false,
      quizCompeted: false,
    };

    this.empty = this.state;
  }

  calculateTotal() {
    let showerTotal =
      this.state.showerVolume *
      this.state.showerEfficiency;
    let bathTotal =
      (this.state.bathValue * volumes.BATH_PER_DAY) /
      volumes.TIME_INTERVALS[this.state.bathIndex].value;
    let bathroomSinkTotal =
      this.state.bathroomSinkVolume *
      this.state.bathroomSinkEfficiency;
    let toiletTotal =
      this.state.toiletVolume *
      this.state.toiletEfficiency;
    let kitchenSinkTotal =
      this.state.kitchenSinkVolume *
      this.state.kitchenEfficiency;
    let dishWashingTotal = 0;
    this.state.dishWashing.forEach((subquestion, i) => {
      dishWashingTotal +=
        (subquestion.value * volumes.DISHWASHING_VOLUMES[i]) /
        volumes.TIME_INTERVALS[subquestion.timeIndex].value;
    });
    let disposableDishesTotal = this.state.household * this.state.disposableDishesValue * volumes.DISPOSABLE_DISHES_PER_PERSON_VOLUME / volumes.TIME_INTERVALS[this.state.disposableDishesIndex].value;
    dishWashingTotal += disposableDishesTotal;

    let laundryTotal = 0;
    this.state.laundry.forEach((subquestion, i) => {
      laundryTotal +=
        (subquestion.value * volumes.LAUNDRY_VOLUMES[i]) /
        volumes.TIME_INTERVALS[subquestion.timeIndex].value;
    });
    let dryCleaningTotal = this.state.dryCleaningValue * volumes.DRY_CLEANING_VOLUME / volumes.TIME_INTERVALS[this.state.dryCleaningIndex].value;
    laundryTotal += dryCleaningTotal;

    let greyWaterTotal = this.state.greywaterValue * volumes.GREYWATER_VOLUME;

    //VIRTUAL TOTALS
    let milesDrivenTotal = this.state.milesDriven * volumes.VOLUME_PER_MILE;
    let shoppingTotal = this.state.shoppingVolume;
    
    let recycleTotal =
      this.state.recyclePaperVolume +
      this.state.recyclePlasicVolume +
      this.state.recycleBottlesVolume +
      this.state.recycleFabricVolume;

    let dietTotal = this.state.dietVolume + this.state.meatConsumptionValue;

    let caffeineTotal =
      (this.state.caffeine[0].value * volumes.CAFFEINE_VOLUMES.coffee) /
        volumes.TIME_INTERVALS[this.state.caffeine[0].timeIndex].value +
      (this.state.caffeine[1].value * volumes.CAFFEINE_VOLUMES.tea) /
        volumes.TIME_INTERVALS[this.state.caffeine[1].timeIndex].value;

    let perPersonTotals =
      showerTotal +
      bathroomSinkTotal + 
      toiletTotal + 
      kitchenSinkTotal
    ;

    let groupTotal =
      bathTotal +
      dishWashingTotal +
      laundryTotal +
      greyWaterTotal +
      milesDrivenTotal;

    let virtualTotals = 
    shoppingTotal +
    recycleTotal +
    dietTotal +
    caffeineTotal;

    let householdTotal = 
      (this.state.household * perPersonTotals) + 
      groupTotal + 
      virtualTotals;

    let personalTotal =
      perPersonTotals + 
      (groupTotal / this.state.household) +
      virtualTotals;

    return {
      personalTotal: Math.round(personalTotal * this.state.unitsMultiplier),
      householdTotal: Math.round(householdTotal * this.state.unitsMultiplier),
      showerTotal: Math.round(showerTotal * this.state.unitsMultiplier),
      bathTotal: Math.round(bathTotal * this.state.unitsMultiplier),
      bathroomSinkTotal: Math.round(bathroomSinkTotal * this.state.unitsMultiplier),
      toiletTotal: Math.round(toiletTotal * this.state.unitsMultiplier),
      kitchenSinkTotal: Math.round(kitchenSinkTotal * this.state.unitsMultiplier),
      dishWashingTotal: Math.round(dishWashingTotal * this.state.unitsMultiplier),
      laundryTotal: Math.round(laundryTotal * this.state.unitsMultiplier),
      greyWaterTotal: Math.round(greyWaterTotal * this.state.unitsMultiplier),
      milesDrivenTotal: Math.round(milesDrivenTotal * this.state.unitsMultiplier),
      shoppingTotal: Math.round(shoppingTotal * this.state.unitsMultiplier),
      recycleTotal: Math.round(recycleTotal * this.state.unitsMultiplier),
      dietTotal: Math.round(dietTotal * this.state.unitsMultiplier),
      caffeineTotal: Math.round(caffeineTotal * this.state.unitsMultiplier),
      totalGallons: Math.round(personalTotal),
    };
  }

  // PREVENTS CALLING CALCULATE MULTIPLE TIMES TO DISPLAY UPDATES?
  setStateAndCalculate(update) {
    this.setState(update, () => {
      let totals = this.calculateTotal();
      console.log(totals);
      this.setState(totals);
      if(this.state.unitsResponse) {
        if(!this.state.quizCompeted) {
          this.setState({quizCompeted: true});
          analytics.logEvent("Final Score", {'gal_day': this.state.personalTotal});
        }
      }
    });
  }

  changeArrayValueAtIndex(array, index, item, amount) {
    let copy = array.slice();
    copy[index][item] = copy[index][item] + amount;
    return copy;
  }

  restart() {
    this.setStateAndCalculate(this.empty);
    let questions = document.getElementsByClassName("questionContainer");
    let numQuestions = questions.length - 1;
    let increment = 100 / numQuestions;
    let progress = document.getElementById("progress");
    index = 0;
    progress.style.width = increment * index + "%";
    questions[index].scrollIntoView();
  }

  render() {
    return (
      <div id="quizContainer">
        <TitlePage
          text={"Find out your water footprint!"}
          next={"Let's go!"}
          hide={true}
        />
        <IntegerQuestion
          question={"How many people are in your household?"}
          value={this.state.household}
          min={1}
          minus={() =>
            this.setStateAndCalculate({ household: this.state.household - 1 })
          }
          plus={() =>
            this.setStateAndCalculate({ household: this.state.household + 1 })
          }
        />
        <MultipleChoiceQuestion
          question={"How long is the average shower in your household?"}
          name={"showerTime"}
          choices={[
            { text: "Less than 5 min", value: volumes.SHOWER_PER_PERSON["<5"] },
            { text: "5 to 10 min", value: volumes.SHOWER_PER_PERSON["5-10"] },
            { text: "11 to 15 min", value: volumes.SHOWER_PER_PERSON["11-15"] },
            {
              text: "More than 15 min",
              value: volumes.SHOWER_PER_PERSON[">15"],
            },
          ]}
          hasResponded={this.state.showerResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              showerVolume: parseInt(event.target.value),
              showerResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you have low-flow shower heads?"}
          description={"Low-flow shower heads use aeration to reduce water use by almost half while maintaining water pressure"}
          name={"showerEfficiency"}
          choices={[
            { text: "Yes", value: volumes.SHOWER_EFFICIENCIES["yes"] },
            { text: "No", value: volumes.SHOWER_EFFICIENCIES["no"] },
            { text: "Some", value: volumes.SHOWER_EFFICIENCIES["some"] },
          ]}
          hasResponded={this.state.showerEfficiencyResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              showerEfficiency: parseFloat(event.target.value),
              showerEfficiencyResponse: true,
            })
          }
        />
        <ComplexQuestion
          question={"Do you take baths? If so how often?"}
          description={"Answer your entire household"}
          value={this.state.bathValue}
          min={0}
          decreaseValue={() =>
            this.setStateAndCalculate({ bathValue: this.state.bathValue - 1 })
          }
          increaseValue={() =>
            this.setStateAndCalculate({ bathValue: this.state.bathValue + 1 })
          }
          timeIndex={this.state.bathIndex}
          decreaseTime={() =>
            this.setStateAndCalculate({ bathIndex: this.state.bathIndex - 1 })
          }
          increaseTime={() =>
            this.setStateAndCalculate({ bathIndex: this.state.bathIndex + 1 })
          }
        />
        <MultipleChoiceQuestion
          question={
            "How long do you run your bathroom taps each day?"
          }
          name={"bathroomSink"}
          choices={[
            {
              text: "Less than 5 min",
              value: volumes.BATHROOM_SINK_PER_PERSON["<5"],
            },
            {
              text: "5 to 10 min",
              value: volumes.BATHROOM_SINK_PER_PERSON["5-10"],
            },
            {
              text: "11 to 30 min",
              value: volumes.BATHROOM_SINK_PER_PERSON["11-30"],
            },
            {
              text: "More than 30 min",
              value: volumes.BATHROOM_SINK_PER_PERSON[">30"],
            },
          ]}
          hasResponded={this.state.bathroomSinkResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              bathroomSinkVolume: parseInt(event.target.value),
              bathroomSinkResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do your bathroom sinks have low-flow taps?"}
          name={"bathroomSinkEfficiency"}
          choices={[
            { text: "Yes", value: volumes.BATHROOM_SINK_EFFICIENCIES["yes"] },
            { text: "No", value: volumes.BATHROOM_SINK_EFFICIENCIES["no"] },
            { text: "Some", value: volumes.BATHROOM_SINK_EFFICIENCIES["some"] },
          ]}
          hasResponded={this.state.bathroomSinkEfficiencyResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              bathroomSinkEfficiency: parseFloat(event.target.value),
              bathroomSinkEfficiencyResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={'Do you let it mellow?'}
          description={"(if its yellow, let it mellow, if its brown, flush it down)"}
          name={"toilet"}
          choices={[
            { text: "Always", value: volumes.TOILET_PER_PERSON["yes"] },
            { text: "Never", value: volumes.TOILET_PER_PERSON["no"] },
            {
              text: "Sometimes",
              value: volumes.TOILET_PER_PERSON["sometimes"],
            },
          ]}
          hasResponded={this.state.toiletResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              toiletVolume: parseInt(event.target.value),
              toiletResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you have low-flow or dual-flush toilets?"}
          description={"These use less than half the water of traditional counterparts. Low-flow toilets use high pressure, often leading to a loud \"whoosh\" sound when flushed."}
          name={"toiletEfficiency"}
          choices={[
            { text: "Yes", value: volumes.TOILET_EFFICIENCIES["yes"] },
            { text: "No", value: volumes.TOILET_EFFICIENCIES["no"] },
            { text: "Some", value: volumes.TOILET_EFFICIENCIES["some"] },
          ]}
          hasResponded={this.state.toiletEfficiencyResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              toiletEfficiency: parseFloat(event.target.value),
              toiletEfficiencyResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={
            "How long do you run your kitchen tap each day?"
          }
          description={
            "Include everything but washing dishes"
          }
          name={"kitchenSink"}
          choices={[
            {
              text: "Less than 5 min",
              value: volumes.KITCHEN_SINK_PER_PERSON["<5"],
            },
            {
              text: "5 to 20 min",
              value: volumes.KITCHEN_SINK_PER_PERSON["5-20"],
            },
            {
              text: "21 to 45 min",
              value: volumes.KITCHEN_SINK_PER_PERSON["21-45"],
            },
            {
              text: "More than 45 min",
              value: volumes.KITCHEN_SINK_PER_PERSON[">45"],
            },
          ]}
          hasResponded={this.state.kitchenSinkResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              kitchenSinkVolume: parseInt(event.target.value),
              kitchenSinkResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Does your kitchen sink have a low-flow tap?"}
          description={"Most modern kitchens have water efficient taps, but if yours was installed prior to 1995, chances are it's not low-flow"}
          name={"kitchenSinkEfficiency"}
          choices={[
            { text: "Yes", value: volumes.KITCHEN_SINK_EFFICIENCIES["yes"] },
            { text: "No", value: volumes.KITCHEN_SINK_EFFICIENCIES["no"] },
          ]}
          hasResponded={this.state.kitchenEfficiencyResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              kitchenEfficiency: parseFloat(event.target.value),
              kitchenEfficiencyResponse: true,
            })
          }
        />
        <MultipleComplexQuestion
          question={"How do you wash your dishes?"}
          description={"Choose all that apply and answer for the whole household"}
          onChange={() =>
            this.setStateAndCalculate({ dishWashingResponse: true })
          }
          hasResponded={this.state.dishWashingResponse}
          subquestions={[
            {
              question: "Old School Dishwasher",

              value: this.state.dishWashing[0].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    0,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    0,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.dishWashing[0].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    0,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    0,
                    "timeIndex",
                    1
                  ),
                }),
            },
            {
              question: "High Efficiency Dishwasher",

              value: this.state.dishWashing[1].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    1,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    1,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.dishWashing[1].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    1,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    1,
                    "timeIndex",
                    1
                  ),
                }),
            },
            {
              question: "By Hand",

              value: this.state.dishWashing[2].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    2,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    2,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.dishWashing[2].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    2,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  dishWashing: this.changeArrayValueAtIndex(
                    this.state.dishWashing,
                    2,
                    "timeIndex",
                    1
                  ),
                }),
            }
          ]}
        />
        <ComplexQuestion
          question={"How often do you get take-out or use disposable dishes?"}
          value={this.state.disposableDishesValue}
          min={0}
          decreaseValue={() =>
            this.setStateAndCalculate({ disposableDishesValue: this.state.disposableDishesValue - 1 })
          }
          increaseValue={() =>
            this.setStateAndCalculate({ disposableDishesValue: this.state.disposableDishesValue + 1 })
          }
          timeIndex={this.state.disposableDishesIndex}
          decreaseTime={() =>
            this.setStateAndCalculate({ disposableDishesIndex: this.state.disposableDishesIndex - 1 })
          }
          increaseTime={() =>
            this.setStateAndCalculate({ disposableDishesIndex: this.state.disposableDishesIndex + 1 })
          }
        />
        <MultipleComplexQuestion
          question={"How do you do laundry?"}
          description={"Choose all that apply and answer for the whole household"}
          onChange={() => this.setStateAndCalculate({ laundryResponse: true })}
          hasResponded={this.state.laundryResponse}
          subquestions={[
            {
              question: "Old School Washing Machine",

              value: this.state.laundry[0].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    0,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    0,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.laundry[0].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    0,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    0,
                    "timeIndex",
                    1
                  ),
                }),
            },
            {
              question: "High Efficiency Washing Machine",

              value: this.state.laundry[1].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    1,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    1,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.laundry[1].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    1,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    1,
                    "timeIndex",
                    1
                  ),
                }),
            },
            {
              question: "By Hand",

              value: this.state.laundry[2].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    2,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    2,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.laundry[2].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    2,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  laundry: this.changeArrayValueAtIndex(
                    this.state.laundry,
                    2,
                    "timeIndex",
                    1
                  ),
                }),
            },
          ]}
        />
        <ComplexQuestion
          question={"How often do you get clothes dry-cleaned?"}
          value={this.state.dryCleaningValue}
          min={0}
          decreaseValue={() =>
            this.setStateAndCalculate({ dryCleaningValue: this.state.dryCleaningValue - 1 })
          }
          increaseValue={() =>
            this.setStateAndCalculate({ dryCleaningValue: this.state.dryCleaningValue + 1 })
          }
          timeIndex={this.state.dryCleaningIndex}
          decreaseTime={() =>
            this.setStateAndCalculate({ dryCleaningIndex: this.state.dryCleaningIndex - 1 })
          }
          increaseTime={() =>
            this.setStateAndCalculate({ dryCleaningIndex: this.state.dryCleaningIndex + 1 })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you have a greywater system installed in your home?"}
          name={"greywater"}
          choices={[
            { text: "Yes", value: 1 },
            { text: "No", value: 0 },
          ]}
          hasResponded={this.state.greywaterResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              greywaterValue: parseFloat(event.target.value),
              greywaterResponse: true,
            })
          }
        />
        <TitlePage
          text={"Now let's move on to your virtual water consumption."}
          description={
            "Almost 90% of our water footprint comes from hidden use from shopping, diet, and other lifestyle habits!"
          }
          next={'Okay!'}
        />
        <MultipleChoiceQuestion
          question={"How much do you shop?"}
          name={"shopping"}
          choices={[
            {
              text: "Just for basics",
              value: volumes.SHOPPING_VOLUMES.low,
            },
            { text: "Often", value: volumes.SHOPPING_VOLUMES.medium },
            { text: "Very Often", value: volumes.SHOPPING_VOLUMES.high },
          ]}
          hasResponded={this.state.shoppingResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              shoppingVolume: parseInt(event.target.value),
              shoppingResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you recycle paper?"}
          name={"recyclePaper"}
          choices={[
            { text: "Always", value: volumes.RECYCLE_PAPER_VOLUMES.all },
            { text: "Sometimes", value: volumes.RECYCLE_PAPER_VOLUMES.some },
            { text: "Never", value: volumes.RECYCLE_PAPER_VOLUMES.none },
          ]}
          hasResponded={this.state.recyclePaperResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              recyclePaperVolume: parseInt(event.target.value),
              recyclePaperResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you recycle plastic?"}
          name={"recyclePlastic"}
          choices={[
            { text: "Always", value: volumes.RECYCLE_PLASTIC_VOLUMES.all },
            { text: "Sometimes", value: volumes.RECYCLE_PLASTIC_VOLUMES.some },
            { text: "Never", value: volumes.RECYCLE_PLASTIC_VOLUMES.none },
          ]}
          hasResponded={this.state.recyclePlasticResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              recyclePlasicVolume: parseInt(event.target.value),
              recyclePlasticResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you recycle bottles and cans?"}
          name={"recycleBottles"}
          choices={[
            { text: "Always", value: volumes.RECYCLE_BOTTLES_VOLUMES.all },
            { text: "Sometimes", value: volumes.RECYCLE_BOTTLES_VOLUMES.some },
            { text: "Never", value: volumes.RECYCLE_BOTTLES_VOLUMES.none },
          ]}
          hasResponded={this.state.recycleBottlesResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              recycleBottlesVolume: parseInt(event.target.value),
              recycleBottlesResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"Do you donate or re-use old clothing, sheets, blankets, and towels?"}
          name={"recycleFabrics"}
          choices={[
            { text: "Always", value: volumes.RECYCLE_FABRICS_VOLUMES.all },
            { text: "Sometimes", value: volumes.RECYCLE_FABRICS_VOLUMES.some },
            { text: "Never", value: volumes.RECYCLE_FABRICS_VOLUMES.none },
          ]}
          hasResponded={this.state.recycleFabricResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              recycleFabricVolume: parseInt(event.target.value),
              recycleFabricResponse: true,
            })
          }
        />
        <MultipleChoiceQuestion
          question={"What is your diet?"}
          name={"diet"}
          choices={[
            { text: "Vegan", value: volumes.DIET_VOLUMES.vegan },
            { text: "Vegetarian", value: volumes.DIET_VOLUMES.vegetarian },
            { text: "Meat Eater", value: volumes.DIET_VOLUMES.meat },
          ]}
          hasResponded={this.state.dietResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              dietVolume: parseInt(event.target.value),
              dietResponse: true,
              hideMeatQuestion:
                parseInt(event.target.value) < 600 ? true : false,
            })
          }
        />
        <MultipleChoiceQuestion
          hide={this.state.hideMeatQuestion}
          question={"How often do you eat meat?"}
          name={"meat"}
          choices={[
            {
              text: "Every Meal",
              value: volumes.MEAT_CONSUMPTION_VOLUMES.every,
            },
            {
              text: "Twice a day",
              value: volumes.MEAT_CONSUMPTION_VOLUMES.twice,
            },
            {
              text: "Once a day",
              value: volumes.MEAT_CONSUMPTION_VOLUMES.once,
            },
            {
              text: "Not every day",
              value: volumes.MEAT_CONSUMPTION_VOLUMES.some,
            },
          ]}
          hasResponded={this.state.meatConsumptionResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              meatConsumptionValue: parseInt(event.target.value),
              meatConsumptionResponse: true,
            })
          }
        />
        <MultipleComplexQuestion
          question={"How much coffee or tea do you drink?"}
          description={"Choose all that apply"}
          hasResponded={true}
          subquestions={[
            {
              question: "Coffee",
              value: this.state.caffeine[0].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    0,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    0,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.caffeine[0].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    0,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    0,
                    "timeIndex",
                    1
                  ),
                }),
            },
            {
              question: "Tea",

              value: this.state.caffeine[1].value,
              min: 0,
              decreaseValue: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    1,
                    "value",
                    -1
                  ),
                }),
              increaseValue: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    1,
                    "value",
                    1
                  ),
                }),

              timeIndex: this.state.caffeine[1].timeIndex,
              decreaseTime: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    1,
                    "timeIndex",
                    -1
                  ),
                }),
              increaseTime: () =>
                this.setStateAndCalculate({
                  caffeine: this.changeArrayValueAtIndex(
                    this.state.caffeine,
                    1,
                    "timeIndex",
                    1
                  ),
                }),
            },
          ]}
        />
        <MultipleChoiceQuestion
          question={"One last question! In what unit do you want your results?"}
          name={"resultUnits"}
          choices={[
            { text: "Liters", value: volumes.GALLONS_TO_LITERS },
            { text: "Gallons", value: 1 },
          ]}
          hasResponded={this.state.unitsResponse}
          onChange={(event) =>
            this.setStateAndCalculate({
              unitsMultiplier: parseInt(event.target.value),
              unitsText: parseInt(event.target.value) === 1 ? 'gal/day' : 'l/day',
              unitsResponse: true,
            })
          }
        />

        <div id="results" className="questionContainer">
          <div className="innerContainer">
            <h2 className="resultTitle">Your Water Footprint Is...</h2>
            <div className="result">
              <div className="resultLabel">Personal</div>
              <div className="resultValue">
                {this.state.personalTotal}
                <span className="resultUnits">{this.state.unitsText}</span>
              </div>
            </div>
            {/* <div className="result" style={{display: this.state.household > 1 ? 'initial' : 'none'}}>
              <div className="resultLabel">Household</div>
              <div className="resultValue">
                {this.state.householdTotal}
                <span className="resultUnits">{this.state.unitsText}</span>
              </div>
            </div> */}
            <div id="avgResults" className="result">
              <div className="resultLabel">Average</div>
              <div className="resultValue">
                {Math.round(volumes.AVG_TOTAL_VOLUME * this.state.unitsMultiplier)}
                <span className="resultUnits">{this.state.unitsText}</span>
              </div>
            </div>

            <div className="nav" style={{ marginTop: "20px" }}>
              <button className="back icon" onClick={scrollBack}>
                back
              </button>
              <button className="next" onClick={() => window.location.reload()}>
                Start Over!
              </button>
            </div>
            <div className="down">
              Check out the breakdown!
              <div className="downIcon"></div>
            </div>
            <h4 className="pieTitle">Personal Use:</h4>
            <Pies
              data={[
                // {name: 'Driving', value: this.state.milesDrivenTotal / this.state.household},/
                { 
                  name: "Shopping",
                  value: this.state.shoppingTotal,
                  units: this.state.unitsText
                },
                { 
                  name: "Diet",
                  value: this.state.dietTotal,
                  units: this.state.unitsText
                },
                { 
                  name: "Caffeine",
                  value: this.state.caffeineTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Showers",
                  value: this.state.showerTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Baths",
                  value: this.state.bathTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Bathroom Sink",
                  value: this.state.bathroomSinkTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Toilet",
                  value: this.state.toiletTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Kitchen Sink",
                  value: this.state.kitchenSinkTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Dish Washing",
                  value: this.state.dishWashingTotal,
                  units: this.state.unitsText
                },
                {
                  name: "Laundry",
                  value: this.state.dishWashingTotal,
                  units: this.state.unitsText
                },
              ]}
            />

            <div>
              <h4 className="tableTitle">Personal Usage: {this.state.unitsText}</h4>
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>You</th>
                    <th>Avg Person</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Shower</td>
                    <td>{this.state.showerTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.shower)}</td>
                  </tr>
                  <tr>
                    <td>Bathtub</td>
                    <td>{this.state.bathTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.bath)}</td>
                  </tr>
                  <tr>
                    <td>Bathroom Sink</td>
                    <td>{this.state.bathroomSinkTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.bathroomSink)}</td>
                  </tr>
                  <tr>
                    <td>Toilet</td>
                    <td>{this.state.toiletTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.toilet)}</td>
                  </tr>
                  <tr>
                    <td>Kitchen Sink</td>
                    <td>{this.state.kitchenSinkTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.kitchenSink)}</td>
                  </tr>
                  <tr>
                    <td>Dish Washing</td>
                    <td>{this.state.dishWashingTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.dishWashing)}</td>
                  </tr>
                  <tr>
                    <td>Laundry</td>
                    <td>{this.state.laundryTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.laundry)}</td>
                  </tr>
                  <tr>
                    <td>Greywater System</td>
                    <td>{this.state.greyWaterTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.greywater)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h4 className="tableTitle">Virtual Usage: {this.state.unitsText}</h4>
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>You</th>
                    <th>Avg Person</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Shopping</td>
                    <td>{this.state.shoppingTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.shopping)}</td>
                  </tr>
                  <tr>
                    <td>Recycling</td>
                    <td>{this.state.recycleTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.recycling)}</td>
                  </tr>
                  <tr>
                    <td>Diet</td>
                    <td>{this.state.dietTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.diet)}</td>
                  </tr>
                  <tr>
                    <td>Caffeine</td>
                    <td>{this.state.caffeineTotal}</td>
                    <td>{Math.round(this.state.unitsMultiplier * volumes.AVG_VOUMES.caffeine)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="ocean"
          style={{
            height: (this.state.personalTotal / (this.state.unitsMultiplier * volumes.MAX_VOLUME) ) * 100 + "vh",
          }}
        >
          <div className="wave"></div>
        </div>
        <div className="progressBack">
          <div className="progressBar">
            <div id="progress"></div>
          </div>
        </div>
      </div>
    );
  }
}

// ========================================

ReactDOM.render(<Quiz />, document.getElementById("root"));
